<template>
  <div class="wayfinder-video" :class="slide.info.settings.position">
    <!-- Video Player -->
    <div class="video-container">
      <!-- Regular video player for uploaded/recorded videos -->
      <video
        v-if="!isExternalVideo"
        :src="videoSource"
        width="100%"
        controls
        @timeupdate="onProgress"
      />
      <!-- Iframe for external video services -->
      <iframe
        v-else
        :src="videoSource"
        width="100%"
        height="100%"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

    <!-- Content Section -->
    <div class="pa-4">
      <h2 v-if="title">{{ title }}</h2>
      <div v-if="description" v-html="description" />
      <WayfinderBody
        v-if="slideContent && slideContent.info.settings.bodyControls"
        :controls="slideContent.info.settings.bodyControls"
      />
      <WayfinderActions
        v-if="slideContent && slideContent.info.settings.actionsControls"
        :controls="slideContent.info.settings.actionsControls"
      />
    </div>
  </div>
</template>

<script>
import {parseTimestamp} from '@/lib.js';
import WayfinderBody from './WayfinderBody.vue';
import WayfinderActions from './WayfinderActions.vue';

export default {
  name: "WayfinderOverlay",
  components: {WayfinderBody, WayfinderActions},
  props: {
    slide: Object,
    slideContent: Object
  },
  data() {
    return {
      lastTime: 0
    };
  },
  computed: {
    title() {
      return this.$store.getters['wayfinder/template'](this.slide.info.settings.wayfinder_title);
    },
    description() {
      return this.$store.getters['wayfinder/template'](this.slide.info.settings.wayfinder_description);
    },
    video() {
      return this.slide.info.settings.video;
    },
    timestamps() {
      if (!this.video.timestamps)
        return null;

      return this.video.timestamps.map(timestamp => {
        return {
          ...timestamp,
          time: parseTimestamp(timestamp.time)
        };
      });
    },
    videoSource() {
      if (!this.video) return null;
      return this.video.body?.url;
    },
    isExternalVideo() {
      if (!this.videoSource) return false;
      try {
        const url = new URL(this.videoSource);
        return (
          url.hostname.includes('youtube.com') ||
          url.hostname.includes('youtu.be') ||
          url.hostname.includes('vimeo.com') ||
          url.hostname.includes('share.synthesia.io')
        );
      } catch (e) {
        return false;
      }
    }
  },
  methods: {
    onProgress(e) {
      if (!this.timestamps)
        return;

      const currentTime = Math.floor(e.target.currentTime);
      if (this.lastTime == currentTime)
        return;
      this.lastTime = currentTime;

      this.timestamps
        .filter(stamp => stamp.time == currentTime)
        .forEach(stamp => this.$store.commit('object/addAnimation', stamp));
    }
  }
};
</script>

<style scoped>
.wayfinder-video {
  position: absolute;
  z-index: 3;
  width: 300px;
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  max-height: calc(100vh - 100px);
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: black;
}

.video-container video,
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.right-bottom {
  right: 0;
  bottom: 64px;
}

.left-bottom {
  left: 0;
  bottom: 64px;
}

.right-top {
  right: 0;
  top: 0;
}

.left-top {
  left: 0;
  top: 0;
}
</style>
