<template>
  <div class="wayfinder-video">
    <!-- Tab-style Video Options -->
    <div class="wayfinder-video__tabs" v-if="!readonly && !hasVideo">
      <div class="tab-group">
        <button
          class="tab-button"
          :class="{ active: activeTab == 'record' }"
          @click="activeTab = 'record'"
        >
          <v-icon small left>mdi-video-account</v-icon>
          Record
        </button>
        <button
          class="tab-button"
          :class="{ active: activeTab == 'upload' }"
          @click="activeTab = 'upload'"
        >
          <v-icon small left>upload</v-icon>
          Upload
        </button>
        <button
          class="tab-button"
          :class="{ active: activeTab == 'url' }"
          @click="activeTab = 'url'"
        >
          <v-icon small left>mdi-link</v-icon>
          URL
        </button>
      </div>
    </div>

    <!-- Video Content Area -->
    <div class="wayfinder-video__content">
      <!-- Input Components -->
      <div v-if="!hasVideo">
        <RecordWebcam
          @close="save"
          v-model="video"
          v-if="activeTab == 'record'"
        />
        <UploadVideo
          @close="save"
          v-model="video"
          v-else-if="activeTab == 'upload'"
        />
        <VideoUrl
          @close="save"
          v-model="video"
          v-else-if="activeTab == 'url'"
        />
      </div>

      <!-- Video Preview -->
      <div v-else-if="hasVideo" class="wayfinder-video__preview">
        <div class="video-container">
          <!-- Regular video player for uploaded/recorded videos -->
          <video
            v-if="!isExternalVideo"
            :src="videoUrl"
            controls
            @timeupdate="onProgress"
          />
          <!-- Iframe for external video services -->
          <iframe
            v-else
            :src="videoUrl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <!-- Compact Video Controls -->
        <div class="video-controls">
          <v-btn small @click="addKeyframe" v-if="hasVideo">
            <v-icon small left>mdi-bookmark</v-icon>
            Add Keyframe
          </v-btn>
          <v-btn 
            small
            v-if="!readonly" 
            @click="removeVideo" 
            color="error" 
            text
            class="ml-2"
          >
            <v-icon small left>mdi-delete</v-icon>
            Remove
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {secToTimestamp} from "@/lib";
import { gray } from "d3-color";
import RecordWebcam from "./VideoSettings/RecordWebcam";
import UploadVideo from "./VideoSettings/UploadVideo";
import VideoUrl from "./VideoSettings/VideoUrl";

export default {
  name: "WayfinderSettings",
  components: { RecordWebcam, UploadVideo, VideoUrl },
  props: {
    value: Object,
    readonly: Boolean
  },
  data() {
    return {
      video: this.value,
      activeTab: 'record',
      time: 0
    };
  },
  computed: {
    activeTabColorUpload() {
      return this.activeTab == 'upload' ? 'rgba(232,0,90,0.2)' : 'gray';
    },
    activeTabColorRecord() {
      return this.activeTab == 'record' ? 'rgba(232,0,90,0.2)' : 'gray';
    },
    activeTabColorUrl() {
      return this.activeTab == 'url' ? 'rgba(232,0,90,0.2)' : 'gray';
    },
    hasVideo() {
      return this.video?.body?.url != null;
    },
    videoUrl() {
      return this.video?.body?.url;
    },
    isExternalVideo() {
      if (!this.videoUrl) return false;
      try {
        const url = new URL(this.videoUrl);
        return (
          url.hostname.includes('youtube.com') ||
          url.hostname.includes('youtu.be') ||
          url.hostname.includes('vimeo.com') ||
          url.hostname.includes('share.synthesia.io')
        );
      } catch (e) {
        return false;
      }
    }
  },
  methods: {
    save() {
      this.activeTab = null;
      this.$emit("input", this.video);
    },
    onProgress(e) {
      this.time = Math.floor(e.target.currentTime);
    },
    addKeyframe() {
      if (!this.video.timestamps)
        this.video.timestamps = [];
      this.video.timestamps.push({
        id: this.guid(),
        objectId: "",
        time: secToTimestamp(this.time)
      });
    },
    removeVideo() {
      this.video = null;
      this.activeTab = null;
      this.$emit("input", null);
    }
  }
};
</script>

<style scoped>
.wayfinder-video {
  padding: 8px;
}

.wayfinder-video__tabs {
  margin-bottom: 16px;
}

.tab-group {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  gap: 2px;
}

.tab-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #666;
  font-size: 14px;
}

.tab-button:hover {
  background: rgba(232,0,90,0.05);
  color: #333;
}

.tab-button.active {
  color: #e8005a;
  border-bottom-color: #e8005a;
  background: rgba(232,0,90,0.1);
}

.tab-button .v-icon {
  margin-right: 4px;
}

.wayfinder-video__content {
  min-height: 240px;
}

.wayfinder-video__preview {
  width: 100%;
}

.video-container {
  width: 100%;
  height: 240px;
  background: black;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.video-container video,
.video-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-controls {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
</style>
