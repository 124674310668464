<template>
  <div>
    <v-text-field
      v-model="videoUrl"
      label="Video URL"
      placeholder="Enter video URL (YouTube, Vimeo, or direct video link)"
      :error-messages="errorMessage"
      @input="validateUrl"
    />

    <v-layout justify-space-between class="mt-2">
      <v-btn v-if="!loading" @click="close">Cancel</v-btn>
      <v-btn
        color="primary"
        @click="submit"
        :disabled="loading || !isValid"
      >
        {{ loading ? "Processing..." : "Add Video" }}
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoUrl: '',
      loading: false,
      isValid: false,
      errorMessage: ''
    };
  },
  methods: {
    validateUrl() {
      // Reset validation state
      this.isValid = false;
      this.errorMessage = '';

      if (!this.videoUrl) {
        this.errorMessage = 'Please enter a video URL';
        return;
      }

      try {
        const url = new URL(this.videoUrl);
        
        // Basic validation for common video platforms
        if (url.hostname.includes('youtube.com') || 
            url.hostname.includes('youtu.be') ||
            url.hostname.includes('vimeo.com') ||
            url.hostname.includes('share.synthesia.io') ||
            this.videoUrl.match(/\.(mp4|webm|ogg)$/i)) {
          this.isValid = true;
        } else {
          this.errorMessage = 'Please enter a valid video URL (YouTube, Vimeo, Synthesia, or direct video link)';
        }
      } catch (e) {
        this.errorMessage = 'Please enter a valid URL';
      }
    },
    submit() {
      this.loading = true;
      
      // Create a video object with the URL
      const videoData = {
        type: 'url',
        body: {
          url: this.transformVideoUrl(this.videoUrl)
        }
      };

      // Emit the video data to parent component
      this.$emit('input', videoData);
      this.$emit('close');
    },
    transformVideoUrl(url) {
      try {
        const urlObj = new URL(url);
        
        // Transform Synthesia share URLs to embed URLs
        if (urlObj.hostname === 'share.synthesia.io') {
          const videoId = urlObj.pathname.split('/').pop(); // Get the last segment of the path
          return `https://share.synthesia.io/embeds/videos/${videoId}`;
        }
        
        return url;
      } catch (e) {
        return url;
      }
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.v-text-field {
  margin-top: 20px;
}
</style>
